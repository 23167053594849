//https://www.npmjs.com/package/i18n-iso-countries

import { CountryCode } from './types';

export type CountryFlagType = {
  unicode: string;
  countryCode: CountryCode;
};

export const countryList: CountryFlagType[] = [
  {
    unicode: '&#127462;&#127467;',
    countryCode: CountryCode.AF
  },
  {
    unicode: '&#127462;&#127485;',
    countryCode: CountryCode.AX
  },
  {
    unicode: '&#127462;&#127473;',
    countryCode: CountryCode.AL
  },
  {
    unicode: '&#127465;&#127487;',
    countryCode: CountryCode.DZ
  },
  {
    unicode: '&#127462;&#127480;',
    countryCode: CountryCode.AS
  },
  {
    unicode: '&#127462;&#127465;',
    countryCode: CountryCode.AD
  },
  {
    unicode: '&#127462;&#127476;',
    countryCode: CountryCode.AO
  },
  {
    unicode: '&#127462;&#127470;',
    countryCode: CountryCode.AI
  },
  {
    unicode: '&#127462;&#127478;',
    countryCode: CountryCode.AQ
  },
  {
    unicode: '&#127462;&#127468;',
    countryCode: CountryCode.AG
  },
  {
    unicode: '&#127462;&#127479;',
    countryCode: CountryCode.AR
  },
  {
    unicode: '&#127462;&#127474;',
    countryCode: CountryCode.AM
  },
  {
    unicode: '&#127462;&#127484;',
    countryCode: CountryCode.AW
  },
  {
    unicode: '&#127462;&#127482;',
    countryCode: CountryCode.AU
  },
  {
    unicode: '&#127462;&#127481;',
    countryCode: CountryCode.AT
  },
  {
    unicode: '&#127462;&#127487;',
    countryCode: CountryCode.AZ
  },
  {
    unicode: '&#127463;&#127480;',
    countryCode: CountryCode.BS
  },
  {
    unicode: '&#127463;&#127469;',
    countryCode: CountryCode.BH
  },
  {
    unicode: '&#127463;&#127465;',
    countryCode: CountryCode.BD
  },
  {
    unicode: '&#127463;&#127463;',
    countryCode: CountryCode.BB
  },
  {
    unicode: '&#127463;&#127486;',
    countryCode: CountryCode.BY
  },
  {
    unicode: '&#127463;&#127466;',
    countryCode: CountryCode.BE
  },
  {
    unicode: '&#127463;&#127487;',
    countryCode: CountryCode.BZ
  },
  {
    unicode: '&#127463;&#127471;',
    countryCode: CountryCode.BJ
  },
  {
    unicode: '&#127463;&#127474;',
    countryCode: CountryCode.BM
  },
  {
    unicode: '&#127463;&#127481;',
    countryCode: CountryCode.BT
  },
  {
    unicode: '&#127463;&#127476;',
    countryCode: CountryCode.BO
  },
  {
    unicode: '&#127463;&#127462;',
    countryCode: CountryCode.BA
  },
  {
    unicode: '&#127463;&#127484;',
    countryCode: CountryCode.BW
  },
  {
    unicode: '&#127463;&#127483;',
    countryCode: CountryCode.BV
  },
  {
    unicode: '&#127463;&#127479;',
    countryCode: CountryCode.BR
  },
  {
    unicode: '&#127470;&#127476;',
    countryCode: CountryCode.IO
  },
  {
    unicode: '&#127463;&#127475;',
    countryCode: CountryCode.BN
  },
  {
    unicode: '&#127463;&#127468;',
    countryCode: CountryCode.BG
  },
  {
    unicode: '&#127463;&#127467;',
    countryCode: CountryCode.BF
  },
  {
    unicode: '&#127463;&#127470;',
    countryCode: CountryCode.BI
  },
  {
    unicode: '&#127472;&#127469;',
    countryCode: CountryCode.KH
  },
  {
    unicode: '&#127464;&#127474;',
    countryCode: CountryCode.CM
  },
  {
    unicode: '&#127464;&#127462;',
    countryCode: CountryCode.CA
  },
  {
    unicode: '&#127464;&#127483;',
    countryCode: CountryCode.CV
  },
  {
    unicode: '&#127463;&#127478;',
    countryCode: CountryCode.BQ
  },
  {
    unicode: '&#127472;&#127486;',
    countryCode: CountryCode.KY
  },
  {
    unicode: '&#127464;&#127467;',
    countryCode: CountryCode.CF
  },
  {
    unicode: '&#127481;&#127465;',
    countryCode: CountryCode.TD
  },
  {
    unicode: '&#127464;&#127473;',
    countryCode: CountryCode.CL
  },
  {
    unicode: '&#127464;&#127475;',
    countryCode: CountryCode.CN
  },
  {
    unicode: '&#127464;&#127485;',
    countryCode: CountryCode.CX
  },
  {
    unicode: '&#127464;&#127464;',
    countryCode: CountryCode.CC
  },
  {
    unicode: '&#127464;&#127476;',
    countryCode: CountryCode.CO
  },
  {
    unicode: '&#127472;&#127474;',
    countryCode: CountryCode.KM
  },
  {
    unicode: '&#127464;&#127468;',
    countryCode: CountryCode.CG
  },
  {
    unicode: '&#127464;&#127465;',
    countryCode: CountryCode.CD
  },
  {
    unicode: '&#127464;&#127472;',
    countryCode: CountryCode.CK
  },
  {
    unicode: '&#127464;&#127479;',
    countryCode: CountryCode.CR
  },
  {
    unicode: '&#127464;&#127470;',
    countryCode: CountryCode.CI
  },
  {
    unicode: '&#127469;&#127479;',
    countryCode: CountryCode.HR
  },
  {
    unicode: '&#127464;&#127482;',
    countryCode: CountryCode.CU
  },
  {
    unicode: '&#127464;&#127484;',
    countryCode: CountryCode.CW
  },
  {
    unicode: '&#127464;&#127486;',
    countryCode: CountryCode.CY
  },
  {
    unicode: '&#127464;&#127487;',
    countryCode: CountryCode.CZ
  },
  {
    unicode: '&#127465;&#127472;',
    countryCode: CountryCode.DK
  },
  {
    unicode: '&#127465;&#127471;',
    countryCode: CountryCode.DJ
  },
  {
    unicode: '&#127465;&#127474;',
    countryCode: CountryCode.DM
  },
  {
    unicode: '&#127465;&#127476;',
    countryCode: CountryCode.DO
  },
  {
    unicode: '&#127466;&#127464;',
    countryCode: CountryCode.EC
  },
  {
    unicode: '&#127466;&#127468;',
    countryCode: CountryCode.EG
  },
  {
    unicode: '&#127480;&#127483;',
    countryCode: CountryCode.SV
  },
  {
    unicode: '&#127468;&#127478;',
    countryCode: CountryCode.GQ
  },
  {
    unicode: '&#127466;&#127479;',
    countryCode: CountryCode.ER
  },
  {
    unicode: '&#127466;&#127466;',
    countryCode: CountryCode.EE
  },
  {
    unicode: '&#127480;&#127487;',
    countryCode: CountryCode.SZ
  },
  {
    unicode: '&#127466;&#127481;',
    countryCode: CountryCode.ET
  },
  {
    unicode: '&#127467;&#127472;',
    countryCode: CountryCode.FK
  },
  {
    unicode: '&#127467;&#127476;',
    countryCode: CountryCode.FO
  },
  {
    unicode: '&#127467;&#127471;',
    countryCode: CountryCode.FJ
  },
  {
    unicode: '&#127467;&#127470;',
    countryCode: CountryCode.FI
  },
  {
    unicode: '&#127467;&#127479;',
    countryCode: CountryCode.FR
  },
  {
    unicode: '&#127468;&#127467;',
    countryCode: CountryCode.GF
  },
  {
    unicode: '&#127477;&#127467;',
    countryCode: CountryCode.PF
  },
  {
    unicode: '&#127481;&#127467;',
    countryCode: CountryCode.TF
  },
  {
    unicode: '&#127468;&#127462;',
    countryCode: CountryCode.GA
  },
  {
    unicode: '&#127468;&#127474;',
    countryCode: CountryCode.GM
  },
  {
    unicode: '&#127468;&#127466;',
    countryCode: CountryCode.GE
  },
  {
    unicode: '&#127465;&#127466;',
    countryCode: CountryCode.DE
  },
  {
    unicode: '&#127468;&#127469;',
    countryCode: CountryCode.GH
  },
  {
    unicode: '&#127468;&#127470;',
    countryCode: CountryCode.GI
  },
  {
    unicode: '&#127468;&#127479;',
    countryCode: CountryCode.GR
  },
  {
    unicode: '&#127468;&#127473;',
    countryCode: CountryCode.GL
  },
  {
    unicode: '&#127468;&#127465;',
    countryCode: CountryCode.GD
  },
  {
    unicode: '&#127468;&#127477;',
    countryCode: CountryCode.GP
  },
  {
    unicode: '&#127468;&#127482;',
    countryCode: CountryCode.GU
  },
  {
    unicode: '&#127468;&#127481;',
    countryCode: CountryCode.GT
  },
  {
    unicode: '&#127468;&#127468;',
    countryCode: CountryCode.GG
  },
  {
    unicode: '&#127468;&#127475;',
    countryCode: CountryCode.GN
  },
  {
    unicode: '&#127468;&#127484;',
    countryCode: CountryCode.GW
  },
  {
    unicode: '&#127468;&#127486;',
    countryCode: CountryCode.GY
  },
  {
    unicode: '&#127469;&#127481;',
    countryCode: CountryCode.HT
  },
  {
    unicode: '&#127469;&#127474;',
    countryCode: CountryCode.HM
  },
  {
    unicode: '&#127469;&#127475;',
    countryCode: CountryCode.HN
  },
  {
    unicode: '&#127469;&#127472;',
    countryCode: CountryCode.HK
  },
  {
    unicode: '&#127469;&#127482;',
    countryCode: CountryCode.HU
  },
  {
    unicode: '&#127470;&#127480;',
    countryCode: CountryCode.IS
  },
  {
    unicode: '&#127470;&#127475;',
    countryCode: CountryCode.IN
  },
  {
    unicode: '&#127470;&#127465;',
    countryCode: CountryCode.ID
  },
  {
    unicode: '&#127470;&#127479;',
    countryCode: CountryCode.IR
  },
  {
    unicode: '&#127470;&#127478;',
    countryCode: CountryCode.IQ
  },
  {
    unicode: '&#127470;&#127466;',
    countryCode: CountryCode.IE
  },
  {
    unicode: '&#127470;&#127474;',
    countryCode: CountryCode.IM
  },
  {
    unicode: '&#127470;&#127473;',
    countryCode: CountryCode.IL
  },
  {
    unicode: '&#127470;&#127481;',
    countryCode: CountryCode.IT
  },
  {
    unicode: '&#127471;&#127474;',
    countryCode: CountryCode.JM
  },
  {
    unicode: '&#127471;&#127477;',
    countryCode: CountryCode.JP
  },
  {
    unicode: '&#127471;&#127466;',
    countryCode: CountryCode.JE
  },
  {
    unicode: '&#127471;&#127476;',
    countryCode: CountryCode.JO
  },
  {
    unicode: '&#127472;&#127487;',
    countryCode: CountryCode.KZ
  },
  {
    unicode: '&#127472;&#127466;',
    countryCode: CountryCode.KE
  },
  {
    unicode: '&#127472;&#127470;',
    countryCode: CountryCode.KI
  },
  {
    unicode: '&#127472;&#127477;',
    countryCode: CountryCode.KP
  },
  {
    unicode: '&#127472;&#127479;',
    countryCode: CountryCode.KR
  },
  {
    unicode: '&#127485;&#127472;',
    countryCode: CountryCode.XK
  },
  {
    unicode: '&#127472;&#127484;',
    countryCode: CountryCode.KW
  },
  {
    unicode: '&#127472;&#127468;',
    countryCode: CountryCode.KG
  },
  {
    unicode: '&#127473;&#127462;',
    countryCode: CountryCode.LA
  },
  {
    unicode: '&#127473;&#127483;',
    countryCode: CountryCode.LV
  },
  {
    unicode: '&#127473;&#127463;',
    countryCode: CountryCode.LB
  },
  {
    unicode: '&#127473;&#127480;',
    countryCode: CountryCode.LS
  },
  {
    unicode: '&#127473;&#127479;',
    countryCode: CountryCode.LR
  },
  {
    unicode: '&#127473;&#127486;',
    countryCode: CountryCode.LY
  },
  {
    unicode: '&#127473;&#127470;',
    countryCode: CountryCode.LI
  },
  {
    unicode: '&#127473;&#127481;',
    countryCode: CountryCode.LT
  },
  {
    unicode: '&#127473;&#127482;',
    countryCode: CountryCode.LU
  },
  {
    unicode: '&#127474;&#127476;',
    countryCode: CountryCode.MO
  },
  {
    unicode: '&#127474;&#127468;',
    countryCode: CountryCode.MG
  },
  {
    unicode: '&#127474;&#127484;',
    countryCode: CountryCode.MW
  },
  {
    unicode: '&#127474;&#127486;',
    countryCode: CountryCode.MY
  },
  {
    unicode: '&#127474;&#127483;',
    countryCode: CountryCode.MV
  },
  {
    unicode: '&#127474;&#127473;',
    countryCode: CountryCode.ML
  },
  {
    unicode: '&#127474;&#127481;',
    countryCode: CountryCode.MT
  },
  {
    unicode: '&#127474;&#127469;',
    countryCode: CountryCode.MH
  },
  {
    unicode: '&#127474;&#127478;',
    countryCode: CountryCode.MQ
  },
  {
    unicode: '&#127474;&#127479;',
    countryCode: CountryCode.MR
  },
  {
    unicode: '&#127474;&#127482;',
    countryCode: CountryCode.MU
  },
  {
    unicode: '&#127486;&#127481;',
    countryCode: CountryCode.YT
  },
  {
    unicode: '&#127474;&#127485;',
    countryCode: CountryCode.MX
  },
  {
    unicode: '&#127467;&#127474;',
    countryCode: CountryCode.FM
  },
  {
    unicode: '&#127474;&#127465;',
    countryCode: CountryCode.MD
  },
  {
    unicode: '&#127474;&#127464;',
    countryCode: CountryCode.MC
  },
  {
    unicode: '&#127474;&#127475;',
    countryCode: CountryCode.MN
  },
  {
    unicode: '&#127474;&#127466;',
    countryCode: CountryCode.ME
  },
  {
    unicode: '&#127474;&#127480;',
    countryCode: CountryCode.MS
  },
  {
    unicode: '&#127474;&#127462;',
    countryCode: CountryCode.MA
  },
  {
    unicode: '&#127474;&#127487;',
    countryCode: CountryCode.MZ
  },
  {
    unicode: '&#127474;&#127474;',
    countryCode: CountryCode.MM
  },
  {
    unicode: '&#127475;&#127462;',
    countryCode: CountryCode.NA
  },
  {
    unicode: '&#127475;&#127479;',
    countryCode: CountryCode.NR
  },
  {
    unicode: '&#127475;&#127477;',
    countryCode: CountryCode.NP
  },
  {
    unicode: '&#127475;&#127473;',
    countryCode: CountryCode.NL
  },
  {
    unicode: '&#127475;&#127464;',
    countryCode: CountryCode.NC
  },
  {
    unicode: '&#127475;&#127487;',
    countryCode: CountryCode.NZ
  },
  {
    unicode: '&#127475;&#127470;',
    countryCode: CountryCode.NI
  },
  {
    unicode: '&#127475;&#127466;',
    countryCode: CountryCode.NE
  },
  {
    unicode: '&#127475;&#127468;',
    countryCode: CountryCode.NG
  },
  {
    unicode: '&#127475;&#127482;',
    countryCode: CountryCode.NU
  },
  {
    unicode: '&#127475;&#127467;',
    countryCode: CountryCode.NF
  },
  {
    unicode: '&#127474;&#127472;',
    countryCode: CountryCode.MK
  },
  {
    unicode: '&#127474;&#127477;',
    countryCode: CountryCode.MP
  },
  {
    unicode: '&#127475;&#127476;',
    countryCode: CountryCode.NO
  },
  {
    unicode: '&#127476;&#127474;',
    countryCode: CountryCode.OM
  },
  {
    unicode: '&#127477;&#127472;',
    countryCode: CountryCode.PK
  },
  {
    unicode: '&#127477;&#127484;',
    countryCode: CountryCode.PW
  },
  {
    unicode: '&#127477;&#127480;',
    countryCode: CountryCode.PS
  },
  {
    unicode: '&#127477;&#127462;',
    countryCode: CountryCode.PA
  },
  {
    unicode: '&#127477;&#127468;',
    countryCode: CountryCode.PG
  },
  {
    unicode: '&#127477;&#127486;',
    countryCode: CountryCode.PY
  },
  {
    unicode: '&#127477;&#127466;',
    countryCode: CountryCode.PE
  },
  {
    unicode: '&#127477;&#127469;',
    countryCode: CountryCode.PH
  },
  {
    unicode: '&#127477;&#127475;',
    countryCode: CountryCode.PN
  },
  {
    unicode: '&#127477;&#127473;',
    countryCode: CountryCode.PL
  },
  {
    unicode: '&#127477;&#127481;',
    countryCode: CountryCode.PT
  },
  {
    unicode: '&#127477;&#127479;',
    countryCode: CountryCode.PR
  },
  {
    unicode: '&#127478;&#127462;',
    countryCode: CountryCode.QA
  },
  {
    unicode: '&#127479;&#127466;',
    countryCode: CountryCode.RE
  },
  {
    unicode: '&#127479;&#127476;',
    countryCode: CountryCode.RO
  },
  {
    unicode: '&#127479;&#127482;',
    countryCode: CountryCode.RU
  },
  {
    unicode: '&#127479;&#127484;',
    countryCode: CountryCode.RW
  },
  {
    unicode: '&#127463;&#127473;',
    countryCode: CountryCode.BL
  },
  {
    unicode: '&#127480;&#127469;',
    countryCode: CountryCode.SH
  },
  {
    unicode: '&#127472;&#127475;',
    countryCode: CountryCode.KN
  },
  {
    unicode: '&#127473;&#127464;',
    countryCode: CountryCode.LC
  },
  {
    unicode: '&#127474;&#127467;',
    countryCode: CountryCode.MF
  },
  {
    unicode: '&#127477;&#127474;',
    countryCode: CountryCode.PM
  },
  {
    unicode: '&#127483;&#127464;',
    countryCode: CountryCode.VC
  },
  {
    unicode: '&#127484;&#127480;',
    countryCode: CountryCode.WS
  },
  {
    unicode: '&#127480;&#127474;',
    countryCode: CountryCode.SM
  },
  {
    unicode: '&#127480;&#127481;',
    countryCode: CountryCode.ST
  },
  {
    unicode: '&#127480;&#127462;',
    countryCode: CountryCode.SA
  },
  {
    unicode: '&#127480;&#127475;',
    countryCode: CountryCode.SN
  },
  {
    unicode: '&#127479;&#127480;',
    countryCode: CountryCode.RS
  },
  {
    unicode: '&#127480;&#127464;',
    countryCode: CountryCode.SC
  },
  {
    unicode: '&#127480;&#127473;',
    countryCode: CountryCode.SL
  },
  {
    unicode: '&#127480;&#127468;',
    countryCode: CountryCode.SG
  },
  {
    unicode: '&#127480;&#127485;',
    countryCode: CountryCode.SX
  },
  {
    unicode: '&#127480;&#127472;',
    countryCode: CountryCode.SK
  },
  {
    unicode: '&#127480;&#127470;',
    countryCode: CountryCode.SI
  },
  {
    unicode: '&#127480;&#127463;',
    countryCode: CountryCode.SB
  },
  {
    unicode: '&#127480;&#127476;',
    countryCode: CountryCode.SO
  },
  {
    unicode: '&#127487;&#127462;',
    countryCode: CountryCode.ZA
  },
  {
    unicode: '&#127468;&#127480;',
    countryCode: CountryCode.GS
  },
  {
    unicode: '&#127480;&#127480;',
    countryCode: CountryCode.SS
  },
  {
    unicode: '&#127466;&#127480;',
    countryCode: CountryCode.ES
  },
  {
    unicode: '&#127473;&#127472;',
    countryCode: CountryCode.LK
  },
  {
    unicode: '&#127480;&#127465;',
    countryCode: CountryCode.SD
  },
  {
    unicode: '&#127480;&#127479;',
    countryCode: CountryCode.SR
  },
  {
    unicode: '&#127480;&#127471;',
    countryCode: CountryCode.SJ
  },
  {
    unicode: '&#127480;&#127466;',
    countryCode: CountryCode.SE
  },
  {
    unicode: '&#127464;&#127469;',
    countryCode: CountryCode.CH
  },
  {
    unicode: '&#127480;&#127486;',
    countryCode: CountryCode.SY
  },
  {
    unicode: '&#127481;&#127484;',
    countryCode: CountryCode.TW
  },
  {
    unicode: '&#127481;&#127471;',
    countryCode: CountryCode.TJ
  },
  {
    unicode: '&#127481;&#127487;',
    countryCode: CountryCode.TZ
  },
  {
    unicode: '&#127481;&#127469;',
    countryCode: CountryCode.TH
  },
  {
    unicode: '&#127481;&#127473;',
    countryCode: CountryCode.TL
  },
  {
    unicode: '&#127481;&#127468;',
    countryCode: CountryCode.TG
  },
  {
    unicode: '&#127481;&#127472;',
    countryCode: CountryCode.TK
  },
  {
    unicode: '&#127481;&#127476;',
    countryCode: CountryCode.TO
  },
  {
    unicode: '&#127481;&#127481;',
    countryCode: CountryCode.TT
  },
  {
    unicode: '&#127481;&#127475;',
    countryCode: CountryCode.TN
  },
  {
    unicode: '&#127481;&#127479;',
    countryCode: CountryCode.TR
  },
  {
    unicode: '&#127481;&#127474;',
    countryCode: CountryCode.TM
  },
  {
    unicode: '&#127481;&#127464;',
    countryCode: CountryCode.TC
  },
  {
    unicode: '&#127481;&#127483;',
    countryCode: CountryCode.TV
  },
  {
    unicode: '&#127482;&#127468;',
    countryCode: CountryCode.UG
  },
  {
    unicode: '&#127482;&#127462;',
    countryCode: CountryCode.UA
  },
  {
    unicode: '&#127462;&#127466;',
    countryCode: CountryCode.AE
  },
  {
    unicode: '&#127468;&#127463;',
    countryCode: CountryCode.GB
  },
  {
    unicode: '&#127482;&#127480;',
    countryCode: CountryCode.US
  },
  {
    unicode: '&#127482;&#127474;',
    countryCode: CountryCode.UM
  },
  {
    unicode: '&#127482;&#127486;',
    countryCode: CountryCode.UY
  },
  {
    unicode: '&#127482;&#127487;',
    countryCode: CountryCode.UZ
  },
  {
    unicode: '&#127483;&#127482;',
    countryCode: CountryCode.VU
  },
  {
    unicode: '&#127483;&#127462;',
    countryCode: CountryCode.VA
  },
  {
    unicode: '&#127483;&#127466;',
    countryCode: CountryCode.VE
  },
  {
    unicode: '&#127483;&#127475;',
    countryCode: CountryCode.VN
  },
  {
    unicode: '&#127483;&#127468;',
    countryCode: CountryCode.VG
  },
  {
    unicode: '&#127483;&#127470;',
    countryCode: CountryCode.VI
  },
  {
    unicode: '&#127484;&#127467;',
    countryCode: CountryCode.WF
  },
  {
    unicode: '&#127466;&#127469;',
    countryCode: CountryCode.EH
  },
  {
    unicode: '&#127486;&#127466;',
    countryCode: CountryCode.YE
  },
  {
    unicode: '&#127487;&#127474;',
    countryCode: CountryCode.ZM
  },
  {
    unicode: '&#127487;&#127484;',
    countryCode: CountryCode.ZW
  }
];

//TODO keep temporal mapping until BE remove all nasty string dependencies
export const countryCodeSpanishLabelMapper: Record<CountryCode, string> = {
  AF: 'Afganistán',
  AL: 'Albania',
  DZ: 'Argelia',
  AS: 'Samoa Americana',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguila',
  AQ: 'Antártida',
  AG: 'Antigua y Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaiyán',
  BS: 'Bahamas',
  BH: 'Bahrein',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Bielorrusia',
  BE: 'Bélgica',
  BZ: 'Belice',
  BJ: 'Benin',
  BM: 'Bermudas',
  BT: 'Bután',
  BO: 'Bolivia',
  BA: 'Bosnia y Herzegovina',
  BW: 'Botswana',
  BV: 'Isla Bouvet',
  BR: 'Brasil',
  IO: 'Territorio Británico del Océano Índico',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Camboya',
  CM: 'Camerún',
  CA: 'Canadá',
  CV: 'Cabo Verde',
  KY: 'Islas Caimán',
  CF: 'República Centroafricana',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Isla de Navidad',
  CC: 'Islas Cocos (Keeling)',
  CO: 'Colombia',
  KM: 'Comoras',
  CG: 'Congo',
  CD: 'Congo (República Democrática del)',
  CK: 'Islas Cook',
  CR: 'Costa Rica',
  CI: 'Costa de Marfil',
  HR: 'Croacia',
  CU: 'Cuba',
  CY: 'Chipre',
  CZ: 'República Checa',
  DK: 'Dinamarca',
  DJ: 'Yibuti',
  DM: 'Dominica',
  DO: 'República Dominicana',
  EC: 'Ecuador',
  EG: 'Egipto',
  SV: 'El Salvador',
  GQ: 'Guinea Ecuatorial',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Etiopía',
  FK: 'Islas Malvinas',
  FO: 'Islas Feroe',
  FJ: 'Fiji',
  FI: 'Finlandia',
  FR: 'Francia',
  GF: 'Guayana Francesa',
  PF: 'Polinesia Francesa',
  TF: 'Tierras Australes Francesas',
  GA: 'Gabón',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Alemania',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Grecia',
  GL: 'Groenlandia',
  GD: 'Granada',
  GP: 'Guadalupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GN: 'Guinea',
  GW: 'Guinea Bissau',
  GY: 'Guyana',
  HT: 'Haití',
  HM: 'Heard e Islas McDonald',
  VA: 'Santa Sede',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungría',
  IS: 'Islandia',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Irán',
  IQ: 'Iraq',
  IE: 'Irlanda',
  IL: 'Israel',
  IT: 'Italia',
  JM: 'Jamaica',
  JP: 'Japón',
  JO: 'Jordania',
  KZ: 'Kazajistán',
  KE: 'Kenia',
  KI: 'Kiribati',
  KP: 'República Popular Democrática de Corea',
  KR: 'República de Corea',
  KW: 'Kuwait',
  KG: 'Kirguistán',
  LA: 'República Democrática Popular de Lao',
  LV: 'Letonia',
  LB: 'Líbano',
  LS: 'Lesoto',
  LR: 'Liberia',
  LY: 'Libia',
  LI: 'Liechtenstein',
  LT: 'Lituania',
  LU: 'Luxemburgo',
  MO: 'Macao',
  MK: 'Macedonia del Norte',
  MG: 'Madagascar',
  MW: 'Malaui',
  MY: 'Malasia',
  MV: 'Maldivas',
  ML: 'Malí',
  MT: 'Malta',
  MH: 'Islas Marshall',
  MQ: 'Martinica',
  MR: 'Mauritania',
  MU: 'Mauricio',
  YT: 'Mayotte',
  MX: 'México',
  FM: 'Micronesia',
  MD: 'Moldavia',
  MC: 'Mónaco',
  MN: 'Mongolia',
  MS: 'Montserrat',
  MA: 'Marruecos',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Países Bajos',
  NC: 'Nueva Caledonia',
  NZ: 'Nueva Zelanda',
  NI: 'Nicaragua',
  NE: 'Níger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Isla Norfolk',
  MP: 'Islas Marianas del Norte',
  NO: 'Noruega',
  OM: 'Omán',
  PK: 'Pakistán',
  PW: 'Palau',
  PS: 'Palestina',
  PA: 'Panamá',
  PG: 'Papua Nueva Guinea',
  PY: 'Paraguay',
  PE: 'Perú',
  PH: 'Filipinas',
  PN: 'Pitcairn',
  PL: 'Polonia',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Catar',
  RE: 'Reunión',
  RO: 'Rumanía',
  RU: 'Rusia',
  RW: 'Ruanda',
  SH: 'Santa Helena, Ascensión y Tristán de Acuña',
  KN: 'Saint Kitts y Nevis',
  LC: 'Santa Lucía',
  PM: 'San Pedro y Miquelón',
  VC: 'San Vicente y las Granadinas',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Santo Tomé y Príncipe',
  SA: 'Arabia Saudita',
  SN: 'Senegal',
  SC: 'Seychelles',
  SL: 'Sierra Leona',
  SG: 'Singapur',
  SK: 'Eslovaquia',
  SI: 'Eslovenia',
  SB: 'Islas Salomón',
  SO: 'Somalia',
  ZA: 'Sudáfrica',
  GS: 'Georgia del Sur y las Islas Sandwich del Sur',
  ES: 'España',
  LK: 'Sri Lanka',
  SD: 'Sudán',
  SR: 'Suriname',
  SJ: 'Svalbard y Jan Mayen',
  SZ: 'Esuatini',
  SE: 'Suecia',
  CH: 'Suiza',
  SY: 'República Árabe Siria',
  TW: 'Taiwán',
  TJ: 'Tayikistán',
  TZ: 'Tanzania',
  TH: 'Tailandia',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad y Tobago',
  TN: 'Túnez',
  TR: 'Turquía',
  TM: 'Turkmenistán',
  TC: 'Islas Turcas y Caicos',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ucrania',
  AE: 'Emiratos Árabes Unidos',
  GB: 'Reino Unido',
  US: 'Estados Unidos',
  UM: 'Islas Ultramarinas Menores de los Estados Unidos',
  UY: 'Uruguay',
  UZ: 'Uzbekistán',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Vietnam',
  VG: 'Islas Vírgenes británicas',
  VI: 'Islas Vírgenes de los Estados Unidos',
  WF: 'Wallis y Futuna',
  EH: 'Sahara Occidental',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabue',
  AX: 'Islas Åland',
  BQ: 'Bonaire, San Eustaquio y Saba',
  CW: 'Curaçao',
  GG: 'Guernsey',
  IM: 'Isla de Man',
  JE: 'Jersey',
  ME: 'Montenegro',
  BL: 'Saint Barthélemy',
  MF: 'Saint Martin (francesa)',
  RS: 'Serbia',
  SX: 'Sint Maarten (neerlandesa)',
  SS: 'Sudán del Sur',
  XK: 'Kosovo'
};

export const spanishLabelToCountryCodeMapper: { [key: string]: CountryCode } = {
  ['Afganistán']: CountryCode.AF,
  ['Albania']: CountryCode.AL,
  ['Argelia']: CountryCode.DZ,
  ['Samoa Americana']: CountryCode.AS,
  ['Andorra']: CountryCode.AD,
  ['Angola']: CountryCode.AO,
  ['Anguila']: CountryCode.AI,
  ['Antártida']: CountryCode.AQ,
  ['Antigua y Barbuda']: CountryCode.AG,
  ['Argentina']: CountryCode.AR,
  ['Armenia']: CountryCode.AM,
  ['Aruba']: CountryCode.AW,
  ['Australia']: CountryCode.AU,
  ['Austria']: CountryCode.AT,
  ['Azerbaiyán']: CountryCode.AZ,
  ['Bahamas']: CountryCode.BS,
  ['Bahrein']: CountryCode.BH,
  ['Bangladesh']: CountryCode.BD,
  ['Barbados']: CountryCode.BB,
  ['Bielorrusia']: CountryCode.BY,
  ['Bélgica']: CountryCode.BE,
  ['Belice']: CountryCode.BZ,
  ['Benin']: CountryCode.BJ,
  ['Bermudas']: CountryCode.BM,
  ['Bután']: CountryCode.BT,
  ['Bolivia']: CountryCode.BO,
  ['Bosnia y Herzegovina']: CountryCode.BA,
  ['Botswana']: CountryCode.BW,
  ['Isla Bouvet']: CountryCode.BV,
  ['Brasil']: CountryCode.BR,
  ['Territorio Británico del Océano Índico']: CountryCode.IO,
  ['Brunei Darussalam']: CountryCode.BN,
  ['Bulgaria']: CountryCode.BG,
  ['Burkina Faso']: CountryCode.BF,
  ['Burundi']: CountryCode.BI,
  ['Camboya']: CountryCode.KH,
  ['Camerún']: CountryCode.CM,
  ['Canadá']: CountryCode.CA,
  ['Cabo Verde']: CountryCode.CV,
  ['Islas Caimán']: CountryCode.KY,
  ['República Centroafricana']: CountryCode.CF,
  ['Chad']: CountryCode.TD,
  ['Chile']: CountryCode.CL,
  ['China']: CountryCode.CN,
  ['Isla de Navidad']: CountryCode.CX,
  ['Islas Cocos (Keeling)']: CountryCode.CC,
  ['Colombia']: CountryCode.CO,
  ['Comoras']: CountryCode.KM,
  ['Congo']: CountryCode.CG,
  ['Congo (República Democrática del)']: CountryCode.CD,
  ['Islas Cook']: CountryCode.CK,
  ['Costa Rica']: CountryCode.CR,
  ['Costa de Marfil']: CountryCode.CI,
  ['Croacia']: CountryCode.HR,
  ['Cuba']: CountryCode.CU,
  ['Chipre']: CountryCode.CY,
  ['República Checa']: CountryCode.CZ,
  ['Dinamarca']: CountryCode.DK,
  ['Yibuti']: CountryCode.DJ,
  ['Dominica']: CountryCode.DM,
  ['República Dominicana']: CountryCode.DO,
  ['Ecuador']: CountryCode.EC,
  ['Egipto']: CountryCode.EG,
  ['El Salvador']: CountryCode.SV,
  ['Guinea Ecuatorial']: CountryCode.GQ,
  ['Eritrea']: CountryCode.ER,
  ['Estonia']: CountryCode.EE,
  ['Etiopía']: CountryCode.ET,
  ['Islas Malvinas']: CountryCode.FK,
  ['Islas Feroe']: CountryCode.FO,
  ['Fiji']: CountryCode.FJ,
  ['Finlandia']: CountryCode.FI,
  ['Francia']: CountryCode.FR,
  ['Guayana Francesa']: CountryCode.GF,
  ['Polinesia Francesa']: CountryCode.PF,
  ['Tierras Australes Francesas']: CountryCode.TF,
  ['Gabón']: CountryCode.GA,
  ['Gambia']: CountryCode.GM,
  ['Georgia']: CountryCode.GE,
  ['Alemania']: CountryCode.DE,
  ['Ghana']: CountryCode.GH,
  ['Gibraltar']: CountryCode.GI,
  ['Grecia']: CountryCode.GR,
  ['Groenlandia']: CountryCode.GL,
  ['Granada']: CountryCode.GD,
  ['Guadalupe']: CountryCode.GP,
  ['Guam']: CountryCode.GU,
  ['Guatemala']: CountryCode.GT,
  ['Guinea']: CountryCode.GN,
  ['Guinea Bissau']: CountryCode.GW,
  ['Guyana']: CountryCode.GY,
  ['Haití']: CountryCode.HT,
  ['Heard e Islas McDonald']: CountryCode.HM,
  ['Santa Sede']: CountryCode.VA,
  ['Honduras']: CountryCode.HN,
  ['Hong Kong']: CountryCode.HK,
  ['Hungría']: CountryCode.HU,
  ['Islandia']: CountryCode.IS,
  ['India']: CountryCode.IN,
  ['Indonesia']: CountryCode.ID,
  ['Irán']: CountryCode.IR,
  ['Iraq']: CountryCode.IQ,
  ['Irlanda']: CountryCode.IE,
  ['Israel']: CountryCode.IL,
  ['Italia']: CountryCode.IT,
  ['Jamaica']: CountryCode.JM,
  ['Japón']: CountryCode.JP,
  ['Jordania']: CountryCode.JO,
  ['Kazajistán']: CountryCode.KZ,
  ['Kenia']: CountryCode.KE,
  ['Kiribati']: CountryCode.KI,
  ['República Popular Democrática de Corea']: CountryCode.KP,
  ['República de Corea']: CountryCode.KR,
  ['Kuwait']: CountryCode.KW,
  ['Kirguistán']: CountryCode.KG,
  ['República Democrática Popular de Lao']: CountryCode.LA,
  ['Letonia']: CountryCode.LV,
  ['Líbano']: CountryCode.LB,
  ['Lesoto']: CountryCode.LS,
  ['Liberia']: CountryCode.LR,
  ['Libia']: CountryCode.LY,
  ['Liechtenstein']: CountryCode.LI,
  ['Lituania']: CountryCode.LT,
  ['Luxemburgo']: CountryCode.LU,
  ['Macao']: CountryCode.MO,
  ['Macedonia del Norte']: CountryCode.MK,
  ['Madagascar']: CountryCode.MG,
  ['Malaui']: CountryCode.MW,
  ['Malasia']: CountryCode.MY,
  ['Maldivas']: CountryCode.MV,
  ['Malí']: CountryCode.ML,
  ['Malta']: CountryCode.MT,
  ['Islas Marshall']: CountryCode.MH,
  ['Martinica']: CountryCode.MQ,
  ['Mauritania']: CountryCode.MR,
  ['Mauricio']: CountryCode.MU,
  ['Mayotte']: CountryCode.YT,
  ['México']: CountryCode.MX,
  ['Micronesia']: CountryCode.FM,
  ['Moldavia']: CountryCode.MD,
  ['Mónaco']: CountryCode.MC,
  ['Mongolia']: CountryCode.MN,
  ['Montserrat']: CountryCode.MS,
  ['Marruecos']: CountryCode.MA,
  ['Mozambique']: CountryCode.MZ,
  ['Myanmar']: CountryCode.MM,
  ['Namibia']: CountryCode.NA,
  ['Nauru']: CountryCode.NR,
  ['Nepal']: CountryCode.NP,
  ['Países Bajos']: CountryCode.NL,
  ['Nueva Caledonia']: CountryCode.NC,
  ['Nueva Zelanda']: CountryCode.NZ,
  ['Nicaragua']: CountryCode.NI,
  ['Níger']: CountryCode.NE,
  ['Nigeria']: CountryCode.NG,
  ['Niue']: CountryCode.NU,
  ['Isla Norfolk']: CountryCode.NF,
  ['Islas Marianas del Norte']: CountryCode.MP,
  ['Noruega']: CountryCode.NO,
  ['Omán']: CountryCode.OM,
  ['Pakistán']: CountryCode.PK,
  ['Palau']: CountryCode.PW,
  ['Palestina']: CountryCode.PS,
  ['Panamá']: CountryCode.PA,
  ['Papua Nueva Guinea']: CountryCode.PG,
  ['Paraguay']: CountryCode.PY,
  ['Perú']: CountryCode.PE,
  ['Filipinas']: CountryCode.PH,
  ['Pitcairn']: CountryCode.PN,
  ['Polonia']: CountryCode.PL,
  ['Portugal']: CountryCode.PT,
  ['Puerto Rico']: CountryCode.PR,
  ['Catar']: CountryCode.QA,
  ['Reunión']: CountryCode.RE,
  ['Rumanía']: CountryCode.RO,
  ['Rusia']: CountryCode.RU,
  ['Ruanda']: CountryCode.RW,
  ['Santa Helena, Ascensión y Tristán de Acuña']: CountryCode.SH,
  ['Saint Kitts y Nevis']: CountryCode.KN,
  ['Santa Lucía']: CountryCode.LC,
  ['San Pedro y Miquelón']: CountryCode.PM,
  ['San Vicente y las Granadinas']: CountryCode.VC,
  ['Samoa']: CountryCode.WS,
  ['San Marino']: CountryCode.SM,
  ['Santo Tomé y Príncipe']: CountryCode.ST,
  ['Arabia Saudita']: CountryCode.SA,
  ['Senegal']: CountryCode.SN,
  ['Seychelles']: CountryCode.SC,
  ['Sierra Leona']: CountryCode.SL,
  ['Singapur']: CountryCode.SG,
  ['Eslovaquia']: CountryCode.SK,
  ['Eslovenia']: CountryCode.SI,
  ['Islas Salomón']: CountryCode.SB,
  ['Somalia']: CountryCode.SO,
  ['Sudáfrica']: CountryCode.ZA,
  ['Georgia del Sur y las Islas Sandwich del Sur']: CountryCode.GS,
  ['España']: CountryCode.ES,
  ['Sri Lanka']: CountryCode.LK,
  ['Sudán']: CountryCode.SD,
  ['Suriname']: CountryCode.SR,
  ['Svalbard y Jan Mayen']: CountryCode.SJ,
  ['Esuatini']: CountryCode.SZ,
  ['Suecia']: CountryCode.SE,
  ['Suiza']: CountryCode.CH,
  ['República Árabe Siria']: CountryCode.SY,
  ['Taiwán']: CountryCode.TW,
  ['Tayikistán']: CountryCode.TJ,
  ['Tanzania']: CountryCode.TZ,
  ['Tailandia']: CountryCode.TH,
  ['Timor-Leste']: CountryCode.TL,
  ['Togo']: CountryCode.TG,
  ['Tokelau']: CountryCode.TK,
  ['Tonga']: CountryCode.TO,
  ['Trinidad y Tobago']: CountryCode.TT,
  ['Túnez']: CountryCode.TN,
  ['Turquía']: CountryCode.TR,
  ['Turkmenistán']: CountryCode.TM,
  ['Islas Turcas y Caicos']: CountryCode.TC,
  ['Tuvalu']: CountryCode.TV,
  ['Uganda']: CountryCode.UG,
  ['Ucrania']: CountryCode.UA,
  ['Emiratos Árabes Unidos']: CountryCode.AE,
  ['Reino Unido']: CountryCode.GB,
  ['Estados Unidos']: CountryCode.US,
  ['Islas Ultramarinas Menores de los Estados Unidos']: CountryCode.UM,
  ['Uruguay']: CountryCode.UY,
  ['Uzbekistán']: CountryCode.UZ,
  ['Vanuatu']: CountryCode.VU,
  ['Venezuela']: CountryCode.VE,
  ['Vietnam']: CountryCode.VN,
  ['Islas Vírgenes británicas']: CountryCode.VG,
  ['Islas Vírgenes de los Estados Unidos']: CountryCode.VI,
  ['Wallis y Futuna']: CountryCode.WF,
  ['Sahara Occidental']: CountryCode.EH,
  ['Yemen']: CountryCode.YE,
  ['Zambia']: CountryCode.ZM,
  ['Zimbabue']: CountryCode.ZW,
  ['Islas Åland']: CountryCode.AX,
  ['Bonaire, San Eustaquio y Saba']: CountryCode.BQ,
  ['Curaçao']: CountryCode.CW,
  ['Guernsey']: CountryCode.GG,
  ['Isla de Man']: CountryCode.IM,
  ['Jersey']: CountryCode.JE,
  ['Montenegro']: CountryCode.ME,
  ['Saint Barthélemy']: CountryCode.BL,
  ['Saint Martin (francesa)']: CountryCode.MF,
  ['Serbia']: CountryCode.RS,
  ['Sint Maarten (neerlandesa)']: CountryCode.SX,
  ['Sudán del Sur']: CountryCode.SS,
  ['Kosovo']: CountryCode.XK
};
